<template>
  <div>
    <div class="page-content">
      <div class="page-sub-title flex flex-center">
        <span class="flex-1">筛选查询</span>
      </div>
      <div>
        <div class="search-item">
          <span class="label">状态</span>
          <el-select
            v-model="searchParams.flag"
            size="small"
            @change="getList()"
          >
            <el-option label="全部" value=""> </el-option>
            <el-option label="启用中" :value="true"> </el-option>
            <el-option label="已禁用" :value="false"> </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <span class="label">数据字典</span>
          <el-input v-model="searchParams.typeName" @change="getList()" size="small"  style="width: 240px" placeholder="请输入数据字典名称"></el-input>
        </div>
        <div class="search-item">
          <el-button type="primary" size="small" @click="getList">查询</el-button>
          <el-button class="border-btn" plain size="small" @click="reset">重置</el-button>
        </div>
      </div>
      <div class="page-sub-title flex flex-center flex-between">
        <span class="flex-1">数据字典列表</span>
        <el-button type="primary" size="small" @click="addDic(1,'')">+ 新增数据字典</el-button>
      </div>
      <el-table
        v-loading="loading"
        class="initTable"
        :data="list"
        ref="theTable"
        style="width: 100%"
        row-key="id"
        :expand-row-keys="isOpen"
        header-row-class-name="table-header"
        :row-style="rowStyle"
        :tree-props="{children: 'dictList', hasChildren: 'hasChildren'}"
      >
        <el-table-column
          align="left"
          prop="typeName"
          label="数据字典"
          width="240"
        >
          <template slot-scope="scope">
            <div class="dic-box">
              <div v-if="!scope.row.typeDescription" class="flex flex-align-center">
                <el-input @change="updateDictTypeName(scope.row)" v-model="scope.row.typeName" size="small" ></el-input>
                <span class="add ml-10" @click="addDic(2,scope.row)">+</span>
              </div>
              <el-input v-else @change="updateDictById(scope.row)" v-model="scope.row.v" size="small" ></el-input>
            </div>
          </template>  
        </el-table-column>
        <el-table-column
          align="center"
          label="状态"
          width="80"
        >
          <template slot-scope="scope">
            <el-switch
              @change="changeDictValidById(scope.row)"
              v-if="scope.row.typeDescription"
              v-model="scope.row.flag">
            </el-switch>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="k"
          label="数据数值"
          width="240"
        >
          <template slot-scope="scope">
            <el-input v-if="scope.row.typeDescription" @change="updateDictById(scope.row)" v-model="scope.row.k" size="small" ></el-input>
            <span v-else>-</span>
          </template> 
        </el-table-column>
        <el-table-column
          align="center"
          prop="seq"
          label="数据排序"
          width="80"
        >
          <template slot-scope="scope">
            <el-input v-if="scope.row.typeDescription" @change="updateDictById(scope.row)" v-model="scope.row.seq" size="small" ></el-input>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="type"
          label="字典代码"
          width="240"
        >
          <template slot-scope="scope">
            <span v-if="!scope.row.typeDescription">{{scope.row.type}}</span>
            <span v-else>-</span>
            <!-- <el-input v-else @change="updateDictById(scope.row)" v-model="scope.row.type" size="small" ></el-input> -->
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="note"
          label="数据描述"
          width="240"
        >
          <template slot-scope="scope">
            <el-input type="textarea" :row="2" v-if="scope.row.typeDescription" @change="updateDictById(scope.row)" v-model="scope.row.note" size="small" ></el-input>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="createTime"
          label="创建时间"
          width="180"
        >
          <template slot-scope="scope">
            <span>{{scope.row.createTime || '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="操作"
        >
          <template slot-scope="scope">
            <el-popconfirm
              title="确定删除吗？"
              confirm-button-text="确认"
              cancel-button-text="取消"
              v-if="scope.row.typeDescription"
              @confirm="removeDictById(scope.row)"
            >
              <el-button slot="reference" type="text"
                >删除</el-button
              >
            </el-popconfirm> 
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        class="text-right m-t-16"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="pageSizes"
        :current-page="pageParams.pageNo"
        :page-size="pageParams.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalItems">
      </el-pagination>
    </div>
    <addDic-dialog
      :visible="addDicVisible"
      @close="closeDialog"
      :type="addType"
      :info="selectInfo"
    ></addDic-dialog>
  </div>
</template>

<script>
import { 
  post_dictList,
  post_updateDictTypeName,
  post_updateDictById,
  post_changeDictValidByType,
  post_changeDictValidById,
  post_removeDictById
} from "@/api/base";
import addDicDialog from "./component/add-dic.vue";
import { pageMixin } from '@/mixins/pageMixin'
export default {
  mixins: [pageMixin],
  components: {
    addDicDialog
  },
  data() {
    return {
      loading: true,
      addDicVisible: false,
      totalItems: 0,
      list: [],
      isOpen: [],
      searchParams: {
        typeName: '',
        flag: '',
      },
      pageParams: {
        pageNo: 1,
        size: 10
      },
      addType: 1,
      selectInfo: {}
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      this.loading = true
      this.isOpen = []
      const res = await post_dictList({
        ...this.pageParams,
        ...this.searchParams
      })
      res.records.map((o,i) => {
        o.id = o.type + i
        if(o.dictList) {
          o.dictList.map(p => {
            // p.flags = p.flag
            p.typeName = p.v
          })
        }
      }) 
      this.list = res ? res.records : []
      // this.forArr(this.list, false)
      this.totalItems = res.total
      this.loading = false
      // console.log(res,11)
    },
    reset() {
      this.searchParams = {
        flag: '',
        typeName: ''
      }
      this.getList()
    },
    rowStyle(row) {
      // console.log(row.row.createTime,11)
      if(row.row.createTime == undefined) {
        return {
          'background-color': '#f9f9f9'
        }
      }
    },
    forArr(arr, isExpand) {
      arr.forEach(i => {
        this.$refs.theTable.toggleRowExpansion(i, isExpand)
        if (i.children) {
          this.forArr(i.children, isExpand)
        }
      })
    },
    // 编辑父节点
    updateDictTypeName(val) {
      if(val.typeName) {
        post_updateDictTypeName({
          type: val.type,
          typeName: val.typeName
        }).then(() => {
          this.$message({
            message: "操作成功",
            type: "success",
          });
        })
      }else {
        this.$message({
          message: "数据字典名称不能为空",
          type: "warning",
        });
      }
      this.getList()
    },
    //编辑子节点
    updateDictById(val) {
      let _data = {
        id: val.id,
        type: val.type,
        k: val.k,
        v: val.v,
        note: val.note,
        seq: val.seq,
        flag: val.flag,
      }
      post_updateDictById(_data).then(() => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        // this.getList()
      }) 
    },
    //启停父节点
    changeDictValidByType(val) {
      post_changeDictValidByType({
        id: val.id,
        flag: val.flag
      }).then(() => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        // this.getList()
      }) 
    },
    //启停子节点
    changeDictValidById(val) {
      post_changeDictValidById({
        id: val.id,
        flag: val.flag
      }).then(() => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        // this.getList()
      })
    },
    //删除
    removeDictById(val) {
      post_removeDictById({
        id: val.id
      }).then(() => {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.getList()
      })
    },
    //新增
    addDic(type, val) {
      this.addType = type
      if(type === 2) { //新增子类
        this.selectInfo = val
      } else { //新增父级
        this.selectInfo = {}
      }
      this.addDicVisible = true
    },
    closeDialog(name) {
      if (name === 'reload') {
        this.getList()
      }
      this.addDicVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep {
    .initTable tbody tr .el-table__cell > .cell {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .initTable {
      .el-table__row--level-0 {
        background: #f9f9f9;
        .el-input__inner {
          text-align: center;
          background: #f9f9f9;
          border: 1px solid transparent;
          &:hover {
            border: 1px solid #409eff;
          }
        }
      }
      .el-table__row--level-1 {
        .el-input__inner {
          text-align: center;
          border: 1px solid transparent;
          &:hover {
            border: 1px solid #409eff;
          }
        }
        .el-textarea__inner {
          border: 1px solid transparent;
          &:hover {
            border: 1px solid #409eff;
          }
        }
      }
    }
  }
  .dic-box {
    .add{ 
      color: #999999;
      border: 1px solid #999999;
      opacity: 0;
      cursor: pointer;
      width: 16px;
      height: 14px;
      text-align: center;
      line-height: 14px;
      &:hover {
        color: #409eff;
        border: 1px solid #409eff;
      }
    }
    &:hover {
      .add {
        opacity: 1;
      }
    }
  }
</style>