<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    v-if="visible"
    width="570px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="hideDialog">
    <div class="dialog-body-content">
      <div class="flex flex-align-center item">
        <div class="title"><span class="c-red">*</span>字典名称</div>
        <el-input class="flex-1" v-model="form.typeDescription" size="small" placeholder="请填写字典名称" :disabled="type === 2?true:false"></el-input>
      </div>
      <div class="flex flex-align-center item mt-20">
        <div class="title"><span class="c-red">*</span>字典代码</div>
        <el-input class="flex-1" v-model="form.type" placeholder="请填写字典代码" size="small" :disabled="type === 2?true:false"></el-input>
      </div>
      <div class="flex flex-align-center item mt-20">
        <div class="title"><span class="c-red">*</span>数据名称</div>
        <el-input class="flex-1" v-model="form.v" placeholder="请填写数据名称" size="small"></el-input>
      </div>
      <div class="flex flex-align-center item mt-20">
        <div class="title"><span class="c-red">*</span>数据数值</div>
        <el-input class="flex-1" v-model="form.k" placeholder="请填写数据数值" size="small"></el-input>
      </div>
      <div class="flex flex-align-center item mt-20">
        <div class="title"><span class="c-red">*</span>数据排序</div>
        <div class="flex-1">
          <el-input class="flex-1" type="number" placeholder="请填写数据排序" v-model="form.seq" size="small"></el-input>
          <div class="font-size-12 c-1">最小0，编号越小越靠前</div>
        </div>
      </div>
       <div class="flex flex-align-center item mt-20">
        <div class="title">数据描述</div>
        <el-input type="textarea" :row="3" class="flex-1" placeholder="请填写数据描述" v-model="form.note" size="small"></el-input>
      </div>
    </div>
    <div slot="footer">
      <el-button size="small" @click="hideDialog()">取消</el-button>
      <el-button type="primary" size="small" @click="addDict">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  post_addDict
} from "@/api/base";
export default {
  props: {
    visible: {
      type: Boolean
    },
    type: {
      type: Number,
    },
    info: {
      type: Object
    }
  },
  watch: {
    visible(val) {
      if(val) {
        if(this.type === 1) {
          this.title = '新增数据字段'
        } else if (this.type === 2) {
          this.title = '新增数据'
          // console.log(this.info,111)
          this.form.typeDescription = this.info.typeName
          this.form.type = this.info.type
        }
      }
    },
  },
  data() {
    return {
      title: '',
      form: {
        typeDescription: '',
        type: '',
        k: '',
        v: '',
        note: '',
        seq: ''
      }
    }
  },
  methods: {
    addDict() {
      if(this.form.typeDescription === '' && this.type === 1) {
        this.$message({
          message: '请填写字典名称',
          type: "warning",
        })
      } else if (this.form.type === '' && this.type === 1) {
        this.$message({
          message: '请填写字典代码',
          type: "warning",
        })
      } else if (this.form.v === '') {
        this.$message({
          message: '请填写数据名称',
          type: "warning",
        })
      } else if (this.form.k === '') {
        this.$message({
          message: '请填写数据数值',
          type: "warning",
        })
      } else if (this.form.seq === '') {
        this.$message({
          message: '请填写数据排序',
          type: "warning",
        })
      } else {
        post_addDict({
          ...this.form
        }).then(() => {
          this.$message({
            message: "新增成功",
            type: "success",
          })
          this.$emit('close','reload')
          this.form = {
            typeDescription: '',
            type: '',
            k: '',
            v: '',
            note: '',
            seq: ''
          }
        })
      }
    },
    hideDialog() {
      this.form = {
        typeDescription: '',
        type: '',
        k: '',
        v: '',
        note: '',
        seq: ''
      }
      this.$emit('close')
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  .el-dialog__footer {
    box-shadow: unset;
  }
}
.form-item{
  padding-left: 7px;
  margin-bottom: 16px;
  .form-label{
    width: 110px;
    color: #333333;
  }
  .grey{
    color: $grey;
  }
}

.dialog-body-content{
  overflow: auto;
  max-height: 500px;
  padding: 0 60px;
  .item {
    .title {
      width: 100px;
    } 
  }
}
.c-red {
  color: red;
}
.c-1 {
  color: #a4a4a4;
}
</style>